/* eslint-disable @typescript-eslint/no-non-null-assertion */
/** @jsxImportSource @emotion/react */
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { addReport, patchLayer } from "../../../../../actions/Layers/actions";
import { FileType, ReportFolder } from "../../../../../constants/misc";
// import useAuthorization from "../../../../../utils/authorization";
import { blobToBase64 } from "../../../../../utils/base64";

export const QC_STATUS = {
    UNREADY: "",
    DRAFT: "DRAFT",
    SENT: "SENT",
};

export default function QCStatusButton({ field, pdf_config, pdfBlob, report_type, draft_report_key }) {
    const dispatch = useDispatch();
    const layer = useSelector<any>((state) => state.layers.current) as any;
    // const auth = useAuthorization();
    const navigate = useNavigate();

    const onSaveDraftReport = async () => {
        if (layer?.id && pdfBlob) {

            const base64Data = await blobToBase64(pdfBlob);

            await dispatch(addReport(report_type, { name: "generated", content: base64Data, type: FileType.PDF }, layer.id, ReportFolder.EYE));
            await dispatch(patchLayer(layer.id, field, QC_STATUS.DRAFT, true, true) as any);

        }
    };

    const onEditReport = async () => {
        await dispatch(patchLayer(layer.id, field, QC_STATUS.UNREADY, true, true) as any);
        await dispatch(patchLayer(layer.id, draft_report_key, "", true, true) as any);

        navigate(`/layer/${layer.id}`);
    };

    if (!pdf_config) {
        return null;
    }

    // TODO: check with Florian what is this for
    // if (!auth.userBelongsToOneOfTeams(pdf_config.teams_that_can_manage_qc_status)) {
    //     // * feature disabled
    //     return null;
    // }

    if (layer[field] === QC_STATUS.SENT) {
        // * Nothing to do here
        return <Button color="primary" size="sm" className="me-2" disabled={true} onClick={() => null}>{pdf_config?.text_report_sent || "Report sent"}</Button>;
    }

    if (layer[field] === QC_STATUS.DRAFT) {
        return <Button color="warning" size="sm" className="me-2" onClick={() => onEditReport()}>{pdf_config?.text_edit_report || "Edit"}</Button>;
    }
    return <Button color="success" size="sm" className="me-2" onClick={() => onSaveDraftReport()}>{pdf_config?.text_save_draft_report || "Save draft report"}</Button>;

}
