/** @jsxImportSource @emotion/react */
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Check, CHECK_LOCATION, Layer, LAYER_TYPE } from "../../../../actions/Layers/constants";
import { AVOCADO_FRUIT } from "../../../../actions/Tenants/config/fruitNames";
import { Loading } from "../../../Helper/Loading";
import { toFixed } from "../fields/formatters";
import { MetaRow } from "./LayerMeta";

export function LayerLabResults() {
    const isLoading = useSelector<any>((state) => state.layers.isLoading);
    const checks = useSelector<any, Check[]>((state) => state.layers.checks);
    const layer = useSelector<any, Layer>((state) => state.layers.current);
    const labChecks = checks.filter((i) => i.location === CHECK_LOCATION.LAB_CHECK).sort((a, b) => b.test_id - a.test_id);

    if (isLoading) {
        return <Loading />;
    }
    // TODO: refactor to use the display_filter
    return <>
        {layer.type === LAYER_TYPE.PURCHASE_ORDER && <div className="pt-4">
            {layer.fruit_type === AVOCADO_FRUIT && <div>
                {labChecks.map((i:any, index) => <MetaRow key={index} label={"AVG dry matter"} value={<Link to={`/layer/${layer.id}/add-check/${i.test_id}/summary`}> {`${toFixed(i.dry_matter_avg || i.manual_dry_matter_avg, 2)} %`}</Link>}></MetaRow>)}
            </div>}
        </div>}
    </>;
}
