/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import {
    getLayer,
    getLayerChecks,
    getLayerChildren, getLayerLocationHistory,
    resetLayerState
} from "../../../../actions/Layers/actions";
import { LAYER_TAB_CATEGORIES, LAYER_TYPE, VIEW } from "../../../../actions/Layers/constants";
import { addBrowseHistory } from "../../../../actions/Navigation/actions";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { isFilledArray } from "../../../../utils";
import { Loading } from "../../../Helper/Loading";
import ArrivalCheck from "./ArrivalCheck";
import BoxWeightCheck from "./BoxWeightCheck";
import FirmnessGraph from "./FirmnessGraph";
import LabCheck from "./LabCheck";
import LayerBreadcrumbs from "./LayerBreadcrumbs";
import LayerChecks from "./LayerChecks";
import LayerLocationHistory from "./LayerLocationHistory";
import { LayerMeta } from "./LayerMeta";
import LayerMetricCardsGroup from "./LayerMetricCardsGroup";
import LayerNotes from "./LayerNotes";
import LayerParentsChildren from "./LayerParentsChildren";
import LayerReportsView from "./LayerReportsView";
import { RipeningStageFirmnessHistoryTable, RipeningStagePressureHistoryTable } from "./RipeningStageHistoryTable";
import { SpecialActionComponents } from "./SpecialActions";
import StartCheckForm from "./StartCheckForm";
import SubLayers from "./SubLayers";

export default function LayerIndex() {
    const params = useParams();
    const layer = useSelector((state) => state.layers.current);
    const isLoading = useSelector((state) => state.layers.isLoading);
    const browse_history = useSelector((state) => state.navigation.browse_history);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const config = useConfig();
    const layer_config = config.get_layer_config(layer);
    const checkLocations = config.get_locations(layer);
    const level = browse_history[browse_history.length - 1]?.level;
    const targetLevel = level - 1;
    const lastBrowse = browse_history.find((item) => item.level === targetLevel);
    const previousPath = lastBrowse?.pathname || `/layer/tab/${LAYER_TAB_CATEGORIES.OPERATION}/first`;


    useEffect(() => {
        dispatch(resetLayerState());
        dispatch(getLayer(params.layer_id));
        dispatch(getLayerLocationHistory(params.layer_id));
    }, [params.layer_id, location]);

    // * Get underlying checks if we need them
    // * Checks are used in calender view
    // * Checks are used to get set latest check in LayerMeta (bad practice, use copy_check_meta instead)
    // * Checks are used in Sublayers (Children table) to set latest check (bad practice, use copy_check_meta instead)

    // * By default load the checks done on the layer itself
    let view_strategy = VIEW.SELF;
    // * CHILDREN will also load checks done on the layer itself (the parent)
    if (layer_config?.show_children) {
        view_strategy = VIEW.CHILDREN;
    } else if (layer_config?.show_checks?.view) {
        // * Make sure only CHILDREN and SELF are allowed
        view_strategy = layer_config?.show_checks?.view === view_strategy.CHILDREN ? VIEW.CHILDREN : VIEW.SELF;
    } else if (layer.type === LAYER_TYPE.PURCHASE_ORDER) {
        view_strategy = VIEW.CHILDREN;
    }

    useEffect(() => {
        const request = { view_strategy, with_meta: true };
        dispatch(getLayerChecks(params.layer_id, request));
    }, [params.layer_id, view_strategy]);


    // Show children if we need to display them
    useEffect(() => {
        // TODO: Readoud meta_display and show_children.fields array to fetch a subset of fields
        dispatch(getLayerChildren(params.layer_id));
    }, [params.layer_id]);

    useEffect(() => {
        if (layer?.id !== params.layer_id || !layer_config?.text || !location?.pathname) {
            return;
        }

        const level = layer.type === LAYER_TYPE.PURCHASE_ORDER ? 1 : 2;

        dispatch(
            addBrowseHistory({
                pathname: location.pathname,
                id: layer.id,
                label: layer.label,
                text: layer_config.text,
                level,
            })
        );
    }, [layer?.id, layer_config?.text, location?.pathname]);


    // Do not unmount the whole page when calling getLayer after an update
    if (isLoading && String(layer.id) !== params.layer_id) {
        return <Loading />;
    }

    const onClose = () => {
        navigate(previousPath);
    };

    if (!layer_config) {
        return null;
    }

    const startCheckButons = [
        ...(layer_config?.special_actions || []).map((i, index) => ({
            position: i.position || (index + 1) * 10,
            el: (
                <div key={index} className="me-2 d-inline-flex d-lg-block">
                    {SpecialActionComponents[i.type](i)}
                </div>
            ),
        })),
        ...(checkLocations || []).filter((i) => !i.is_hidden).map((i, index) => ({
            position: i.position,
            el: <div key={index + 0.5} className="me-2 d-inline-flex d-lg-block">
                <StartCheckForm location_config={i} />
            </div>
        }))
    ].sort((a, b) => a.position - b.position).map((i) => i.el);

    return <div>
        <div className="px-3 py-4 bg-gray">
            <Container>
                <div className="d-flex justify-content-end align-items-center">
                    <div className="pb-2 pb-sm-0 me-2 d-inline-flex d-md-block">
                        <h3 className="mb-0 ">{layer_config?.text} {layer.label} </h3>
                    </div>
                    <div className="pb-2 pb-sm-0 d-flex align-items-center ms-auto">
                        <Button className="btn-close my-1" size="lg" onClick={() => onClose()} ></Button>
                    </div>
                </div>
                <LayerBreadcrumbs />
            </Container>
        </div>
        <Container>
            <div className="py-4 d-lg-flex justify-content-end align-items-center">
                {startCheckButons}
            </div>
        </Container>
        <Container>
            <Row>
                <Col xxl="4" xl="5" lg="6" md="12">
                    <LayerMeta />
                    {layer_config?.show_upload_reports_button && LAYER_TYPE.PURCHASE_ORDER && <LayerReportsView layer={layer} />}
                    <LayerParentsChildren />
                    <LayerLocationHistory />
                </Col>
                <Col xxl="8" xl="7" lg="6" md="12">
                    {isFilledArray(layer_config.show_metric_cards) && <LayerMetricCardsGroup />}
                    {layer_config.show_checks && <LayerChecks subconfig={layer_config.show_checks} />}
                    {layer_config.show_box_weight_check && <BoxWeightCheck title={layer_config.show_box_weight_check?.title} />}
                    {layer_config.show_arrival_check && <ArrivalCheck subconfig={layer_config.show_arrival_check?.fields} title={layer_config.show_arrival_check?.title} />}
                    {layer_config.show_lab_check && <LabCheck title={layer_config.show_lab_check?.title} />}
                    {layer_config?.show_advance_ripening && <SubLayers subconfig={layer_config?.show_advance_ripening} show_art={true} />}

                    {/* {layer_config?.show_images && <LayerImagesWrapped required_images={layer_config?.show_images} /> } */}
                    {layer_config?.show_ripening_progress_firmness_history_table && <RipeningStageFirmnessHistoryTable subconfig={layer_config?.show_ripening_progress_firmness_history_table} />}
                    {layer_config?.show_ripening_progress_pressure_history_table && <RipeningStagePressureHistoryTable />}
                    {layer_config?.show_ripening_progress && <FirmnessGraph title={layer_config?.show_ripening_progress.title} subconfig={layer_config?.show_ripening_progress} />}
                    {layer_config?.show_children && <SubLayers subconfig={layer_config?.show_children} />}
                    {layer_config?.show_general_notes && <LayerNotes />}
                </Col>
            </Row>
        </Container>
    </div >;
}

