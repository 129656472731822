import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCheck, resetCheckState, triggerCheckBusinessRules } from "../../../actions/Checks/actions";
import { getLayer, resetLayerState } from "../../../actions/Layers/actions";
import { isArtLocation } from "../../../actions/Layers/constants";
import useConfig from "../../../actions/Tenants/config/configHook";
import {
    path_advance_ripening,
    path_atron, path_atron_results, path_brix_input, path_color_check,
    path_dry_matter, path_fta_input, path_internal_color_check, path_mangos, path_manual_check,
    path_manual_fruit_form,
    path_manual_or_with_mini,
    path_maturity,
    path_results_with_form,
    path_summary, path_temperature,
    path_visual_check,
    path_weight_input
} from "../../../actions/Tenants/config/constants";
import { Loading } from "../../Helper/Loading";
import NoMatch from "../../NoMatch";
import AdvancedRipening from "./components/AdvanceRipening";
import BrixInput from "./components/BrixInput";
import BulkFTAInput from "./components/BulkFTAInput";
import BulkWeightInput from "./components/BulkWeightInput";
import DeviceResults from "./components/DeviceResults";
import DryMatterInput from "./components/DryMatterInput";
import ManualFruitForm from "./components/ManualFruitForm";
import MaturityInput from "./components/MaturityInput";
import {
    ExternalColorCheck, InternalColorCheck
} from "./components/QualityCheck";
import CheckSummary from "./components/Summary/CheckSummary";
import TemperatureInput from "./components/TemperatureInput";
import VisualCheck from "./components/VisualCheck";

export default function Check() {
    const params = useParams();
    const check = useSelector((state) => state.checks.current);
    const layer = useSelector((state) => state.layers.current);
    const checkLoading = useSelector((state) => state.checks.isLoading);
    const layerLoading = useSelector((state) => state.layers.isLoading);
    const config = useConfig();


    const dispatch = useDispatch();

    useEffect(() => {
        const param_test_id = parseFloat(params.test_id);
        if (param_test_id > 0) {
            // only fetch check again current check is not fetched yet or we load a specific page thar requirers refreshed predictions
            // * if we are on the advance ripening page, and we switch from check to check, we don't want to reset the check state, as that will cause the page to be in a loading state
            if (param_test_id !== check.test_id && !isArtLocation(check?.location)) {
                dispatch(resetCheckState());
            }

            // * call business rules every time we go to summary page or advance ripening page
            if (params.action === path_summary || params.action === path_advance_ripening) {
                dispatch(triggerCheckBusinessRules(param_test_id));
            } else if (param_test_id !== check?.test_id) {
                dispatch(getCheck(param_test_id));
            }


        }
    }, [params.test_id, params.action]);


    useEffect(() => {
        if (String(layer.id) !== params.layer_id) {
            dispatch(resetLayerState());
        }
        if (String(layer.id) !== params.layer_id || [path_summary].includes(params.action)) {
            dispatch(getLayer(params.layer_id));
        }
    }, [params.layer_id, layer.id, params.action]);

    const disable_loading = [path_advance_ripening];


    if (
        !check.test_id
        || !layer.id
        || checkLoading
        || layerLoading
        || String(layer.id) !== params.layer_id
        || parseFloat(params.test_id) !== check.test_id
    ) {
        if (!disable_loading.includes(params.action)) {
            return <Loading />;
        }

    }
    const location_config = config.get_location(layer, check);

    if (!location_config) {
        return <Loading/>;
    }

    switch (params.action) {
    case path_atron:
    case path_atron_results:
    case path_mangos:
    case path_results_with_form:
        return <DeviceResults />;
    case path_manual_or_with_mini: {
        if (check.is_manual) {
            return <ManualFruitForm />;
        }
        return <DeviceResults />;
    }
    case path_manual_check:
    case path_manual_fruit_form:
        return <ManualFruitForm />;
    // case path_external_quality_check:
    //     return <ExternalQualityCheck />;
    case path_fta_input:
        return <BulkFTAInput />;
    case path_weight_input:
        return <BulkWeightInput />;
    case path_brix_input:
        return <BrixInput />;
    case path_dry_matter:
        return <DryMatterInput />;
    case path_internal_color_check:
        return <InternalColorCheck />;
    // case path_internal_quality_check:
    //     return <InternalQualityCheck />;
    case path_color_check:
        return <ExternalColorCheck />;
    case path_temperature:
        return <TemperatureInput />;
    case path_maturity:
        return <MaturityInput />;
    case path_summary:
        return <CheckSummary />;
    case path_visual_check:
        return <VisualCheck />;
    case path_advance_ripening:
        return <AdvancedRipening/>;
    default:
        return <NoMatch />;
    }
}
